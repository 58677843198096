<template>
    <div class="pc-container">
        <Nav :curIndex="0"></Nav>
        <div class="main">
            <div class="box1">
                <div class="balance-header">
                    <div class="left">
                        <div class="asset-title">{{$t('Assets.TotalBalance')}}</div>
                        <div class="asset-sum animate__animated animate__bounceIn">{{assetSum}}USDT ≈ {{assetSum}}$</div>
                    </div>
                    <el-button :loading="loading" class="refresh-btn" @click="refresh">{{$t('Assets.Refresh')}}</el-button>
                </div>
                <el-table :data="assetList" style="width: 100%;">
                    <el-table-column prop="type" :label="$t('Assets.Currency')">
                    </el-table-column>
                    <el-table-column prop="num" :label="$t('Assets.AvailableBalance')">
                    </el-table-column>
                    <el-table-column prop="frozen" :label="$t('Assets.Frozen')">
                    </el-table-column>
                    <el-table-column :label="$t('Assets.Operate')">
                        <template slot-scope="scope">
                            <div class="operate-btns">
                                <div class="operate-btn" @click="to('/assets/deposit',scope.row.type)">{{$t('Assets.Deposit')}}</div>
                                <div class="operate-btn" @click="to('/assets/withdraw',scope.row.type)">{{$t('Assets.Withdraw')}}</div>
                                <div class="operate-btn" @click="to('/assets/transfer',scope.row.type)">{{$t('Assets.Transfer')}}</div>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="box2">
                <div class="filter">
                    <div class="label">{{$t('Assets.Type')}}</div>
                    <el-select v-model="type" placeholder="" @change="change">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <el-table :data="recordList" style="width: 100%;">
                    <el-table-column prop="created_at" :label="$t('Assets.Time')">
                    </el-table-column>
                    <el-table-column :label="$t('Assets.Type')">
                        <template slot-scope="scope">
                            <span>{{curType(scope.row.type)}}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="user_credit_type" :label="$t('Assets.Currency')">
                    </el-table-column>
                    <el-table-column :label="$t('Assets.Quantity')">
                        <template slot-scope="scope">
                            <span>{{parseFloat(scope.row.num)}}</span>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column prop="fee" label="手续费">
                    </el-table-column>
                    <el-table-column prop="balance" label="账户余额">
                    </el-table-column>
                    <el-table-column prop="remark" label="备注">
                    </el-table-column> -->
                </el-table>
                <div v-if="recordList.length > 0" class="pagination-box">
                    <el-pagination @current-change="handleCurrentChange"
                        :current-page="currentPage" :pager-count="5"
                        :page-sizes="[pageSize, pageSize * 2, pageSize * 3]"
                        :page-size="pageSize" layout="prev, pager, next, jumper"
                        :total="total" background>
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        components: {
            Nav: () => import('./nav.vue')
        },
        data() {
            return {
                isCompleted1: false,
                isCompleted2: false,
                options: [
                    {
                        value: '',
                        label: this.$t('FundingType.All')
                    },
                    {
                        value: 'system',
                        label: this.$t('Assets.Deposit')
                    },
                    {
                        value: 'commission',
                        label: this.$t('FundingType.commission')
                    },
                    {
                        value: 'recharge',
                        label: this.$t('FundingType.recharge')
                    },
                    {
                        value: 'withdraw',
                        label: this.$t('FundingType.withdraw')
                    },
                    {
                        value: 'withdrawFail',
                        label: this.$t('FundingType.withdrawFail')
                    },
                    {
                        value: 'second_order',
                        label: this.$t('FundingType.second_order')
                    },
                    {
                        value: 'secondOrderWin',
                        label: this.$t('FundingType.secondOrderWin')
                    },
                    {
                        value: 'cancelSOrder',
                        label: this.$t('FundingType.cancelSOrder')
                    },
                    {
                        value: 'perpetual_order',
                        label: this.$t('FundingType.perpetual_order')
                    },
                    {
                        value: 'perpeTualOrderClo',
                        label: this.$t('FundingType.perpeTualOrderClo')
                    },
                    {
                        value: 'trade',
                        label: this.$t('FundingType.trade')
                    },
                    {
                        value: 'buyFinance',
                        label: this.$t('FundingType.buyFinance')
                    },
                    {
                        value: 'financeProfit',
                        label: this.$t('FundingType.financeProfit')
                    },
                    {
                        value: 'financeMoney',
                        label: this.$t('FundingType.financeMoney')
                    }
                ],
                type: '',
                recordList: [],
                currentPage: 1,
                pageSize: 1,
                total: 1,
                credit: [],
                frozen: '0.00000000'
            }
        },
        created() {
            this.refresh()
            this.getRecordList(true)
        },
        computed: {
            loading() {
                return !this.isCompleted1 && !this.isCompleted2
            },
            
            productList() {
                return this.$store.state.productList
            },
            
            calCoinValue() {
                return (name,num) => {
                    let cur_coin = this.$store.state.productList.find(item => name == item.name)
                    return cur_coin ? num * parseFloat(cur_coin.price.close) : 0
                }
            },
            
            assetList() {
                let list = []
                list = JSON.parse(JSON.stringify(this.credit.map(item => {
                    if(item.type == 'USDT') {
                        item['frozen'] = this.frozen
                    } else {
                        item['frozen'] = '0.00000000'
                    }
                    
                    return item
                })))
                
                return list
            },
            
            usdtSum() {
                let val = 0
                this.assetList.forEach(item => {
                    if(item.type == 'USDT') {
                        val += parseFloat(item.num) + parseFloat(item.frozen)
                    }
                })
                
                return val
            },
            
            btcSum() {
                let val = 0
                this.assetList.forEach(item => {
                    if(item.type == 'BTC') {
                        val += this.calCoinValue(item.type,parseFloat(item.num))
                    }
                })
                
                return val
            },
            
            ethSum() {
                let val = 0
                this.assetList.forEach(item => {
                    if(item.type == 'ETH') {
                        val += this.calCoinValue(item.type,parseFloat(item.num))
                    }
                })
                
                return val
            },
            
            assetSum() {
                return (this.usdtSum + this.btcSum + this.ethSum).toFixed(8)
            },
            
            curType() {
                return type => {
                    let name = ''
                    this.options.forEach(item => {
                        if(type == item.value) {
                            name = item.label
                        }
                    })
                    
                    return name
                }
            }
        },
        methods: {
            to(path,coin_name) {
                this.$router.push({
                    path,
                    query: {
                        coin_name
                    }
                })
            },
            
            getCredit() {
                this.isCompleted1 = false
                this.$api.credit().then(res => {
                    this.isCompleted1 = true
                    this.credit = res.data
                })
            },
            
            getFrozen() {
                this.isCompleted2 = false
                this.$api.frozen().then(res => {
                    this.isCompleted2 = true
                    this.frozen = res.data
                })
            },
            
            refresh() {
                this.getCredit()
                this.getFrozen()
            },
            
            change(e) {
                // console.log(e)
                this.getRecordList(true)
            },
            
            getRecordList(bool=false) {
                this.$api.credit_logs({
                    type: this.type,
                    page: this.currentPage
                }).then(res => {
                    if(bool) {
                        this.pageSize = res.data.per_page
                        this.total = res.data.total
                    }
                    this.recordList = res.data.data
                })
            },
            
            handleCurrentChange(val) {
                // console.log(`当前页: ${val}`)
                this.currentPage = val
                this.getRecordList()
            }
        }
    }
</script>

<style lang="scss" scoped>
    .pc-container {
        display: flex;
        width: 100%;
        box-sizing: border-box;
        padding: 25px $box-padding 40px;
        background-color: #f6f6f6;

        .main {
            width: $main-width;
            
            /deep/ .el-table th.is-leaf {
                padding: 12px 0;
                background-color: #ECEEF8;
            
                .cell {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 14px;
                    font-weight: normal;
                    color: #6D6F7E;
                    text-align: center;
                }
            }
            
            /deep/ .el-table__empty-block {
                min-height: 100px;
            
                .el-table__empty-text {
                    line-height: 100px;
                    font-size: 15px;
                }
            }
            
            /deep/ .el-table--enable-row-hover .el-table__body tr:hover>td {
                background-color: #F3F5FF;
            }
            
            .box1 {
                width: 100%;
                box-sizing: border-box;
                padding: 40px 40px 60px;
                margin-bottom: 25px;
                background-color: #FFFFFF;
                
                .balance-header {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    box-sizing: border-box;
                    padding: 40px 40px 50px;
                    margin-bottom: 25px;
                    background: url(~@/assets/images/pc/assets/assets-bg.png) no-repeat;
                    background-size: 100% 100%;
                    
                    .left {
                        max-width: 100%;
                        
                        .asset-title {
                            width: 100%;
                            margin-bottom: 32px;
                            font-size: 18px;
                            font-weight: bold;
                            color: #FFFFFF;
                            word-wrap: break-word;
                        }
                        
                        .asset-sum {
                            width: 100%;
                            font-size: 38px;
                            font-weight: bold;
                            color: #FFFFFF;
                            word-break: break-all;
                            animation-delay: 400ms;
                        }
                    }
                    
                    .refresh-btn {
                        height: 40px;
                        line-height: 40px;
                        box-sizing: border-box;
                        padding: 0 30px;
                        margin-left: 40px;
                        border: 0;
                        border-radius: 4px;
                        font-size: 14px;
                        color: #F08834;
                        text-align: center;
                        white-space: nowrap;
                        background-color: #FFFFFF;
                        cursor: pointer;
                    }
                }
                
                /deep/ .el-table td {
                    padding: 20px 0;
                
                    .cell {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 15px;
                        color: #000000;
                        text-align: center;
                    }
                }
                
                .operate-btns {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 100%;
                    
                    .operate-btn {
                        height: 34px;
                        line-height: 34px;
                        box-sizing: border-box;
                        padding: 0 16px;
                        border-radius: 4px;
                        font-size: 14px;
                        color: #6D6F7E;
                        text-align: center;
                        white-space: nowrap;
                        background-color: #E2E3ED;
                        cursor: pointer;
                        
                        &:not(:last-child) {
                            margin-bottom: 6px;
                        }
                        
                        &:hover {
                            color: #FFFFFF;
                            background-color: #F08834;
                        }
                    }
                }
            }
            
            .box2 {
                width: 100%;
                box-sizing: border-box;
                padding: 40px 40px 60px;
                background-color: #FFFFFF;
                
                .filter {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    width: 100%;
                    margin-bottom: 25px;
                    
                    .label {
                        margin-right: 24px;
                        font-size: 14px;
                        color: #000000;
                        white-space: nowrap;
                    }
                    
                    /deep/ .el-input__inner {
                        border: 1px solid #e6e6e6;
                        font-size: 14px;
                        color: #000000;
                    }
                }
                
                /deep/ .el-table td {
                    padding: 16px 0;
                
                    .cell {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 14px;
                        color: #000000;
                        text-align: center;
                    }
                }
                
                .pagination-box {
                    display: flex;
                    justify-content: flex-end;
                    width: 100%;
                    box-sizing: border-box;
                    padding: 40px 0 0 0;
                    
                    /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
                        background-color: #F08834;
                    }
                    
                    /deep/ .el-pager li:not(.active):hover {
                        color: #F08834;
                    }
                }
            }
        }
    }
</style>
